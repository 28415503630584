/**
 * @generated SignedSource<<e14420b101ff25b585a79e2846db989f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MemberGroupMembershipRole = "lead" | "member" | "%future added value";
export type OrganizationRole = "admin" | "member" | "owner" | "%future added value";
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberGroupMembersTableRowFragment$data = {
  readonly id: string;
  readonly organizationMembership: {
    readonly email: string | null;
    readonly role: OrganizationRole;
  };
  readonly productMembership: {
    readonly email: string | null;
    readonly role: ProductRole;
  } | null;
  readonly role: MemberGroupMembershipRole;
  readonly user: {
    readonly fullName: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  };
  readonly " $fragmentType": "MemberGroupMembersTableRowFragment";
};
export type MemberGroupMembersTableRowFragment$key = {
  readonly " $data"?: MemberGroupMembersTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupMembersTableRowFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberGroupMembersTableRowFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "organizationMembership",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "productMembership",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarWithDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MemberGroupMembership",
  "abstractKey": null
};
})();

(node as any).hash = "0418863f893a6e04de9eedf14acb73a1";

export default node;
